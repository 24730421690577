@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

@font-face {
  font-family: 'permanent_markerregular';
  src: url('permanentmarker-regular-webfont.woff2') format('woff2'),
       url('permanentmarker-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* || VARIABLES */

:root {
  /* COLORS */
  --BGCOLOR: white;
  --BORDER-COLOR: #FFF;
  --COLOR: #171717;
  --ERROR: firebrick;
  --INACTIVE: lightgray;
  --LINK-COLOR: whitesmoke;
  --LINK-HOVER: rgba(245, 245, 245, 0.9);
  --NOTE-BGCOLOR: hsla(240, 64%, 27%, 0.65);
  --STATUS-COMPLETE: limegreen;
  --STATUS-OPEN: firebrick;
  --TABLE-BGCOLOR: #FFF;
  --TABLE-COLOR: black;
  --INPUT-INVALID: #F00;
}

/* || UTILITY CLASSES */
.errmsg {
  display: inline-block;
  background-color: var(--TABLE-BGCOLOR);
  color: var(--ERROR);
  padding: 0.25em;
  margin-bottom: 0.5em;
}

.errmsg a:any-link {
  color: var(--ERROR);
  text-decoration: underline;
}

.nowrap {
  white-space: nowrap;
}

.icon-button {
  width: 48px;
  height: 48px;
  font-size: 2.5rem;
  background-color: transparent;
  border: none;
  color: #FFEE7E;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.icon-button:hover,
.icon-button:focus-visible {
  transform: scale(1.2);
}

.icon-button[disabled] {
  visibility: hidden;
}

/* || GENERAL STYLES */

body {
  font-family: Arial, sans-serif;
  min-height: 100vh;
  margin: 0;
  background-color: var(--BGCOLOR);
  color: #171717;
}

input,
button,
textarea,
select {
  font: inherit;
}

#root {
  /* React root div */
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
}

a {
  text-decoration: none;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus-visible {
  color: #FFEE7E;
}

/* || PAGES */

.public,
.welcome {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.public {
  flex-grow: 1;
  margin-top: 5%;
  margin-left: 5%;
}

.public-header {
  flex-grow: 1;
  margin-top: 5%;
  margin-left: 5%;
}

.public__main,
.login {
  flex-grow: 1;
  padding: 0;
}

.public__addr {
  margin-top: 1em;
}

.welcome a::before {
  content: "➜ "
}

/* || DASH HEADER, CONTAINER & FOOTER */

.dash-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  padding: 0 0.5em;
  border-bottom: 1px solid var(--BORDER-COLOR);
}

.dash-header__container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

.dash-header__container--small {
  max-width: 800px;
}

.dash-header__nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  color: #FFEE7E;
  cursor: pointer;
  margin-right: 10%;
  align-items: baseline;
  margin-top: 15%;
}

.dash-header-links {
  color: #FFEE7E
}

.dash-container {
  padding: 0.75em 0.5em 0.5em;
  flex-grow: 1;
}

.dash-content {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.dash-actions {
  display: flex;
  flex-direction: column;
  width: 40vw;
  height: 100vh;
}

.dash-footer {
  font-size: 1rem;
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: var(--BGCOLOR);
  padding: 0.5em;
  border-top: 1px solid var(--BORDER-COLOR);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 1em;
}

.dash-footer__button {
  font-size: 1rem;
  width: auto;
  height: auto;
}

/* || TABLES - GENERAL */

thead,
tbody,
tr {
  display: contents;
}

.table {
  font-size: 1rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  color: var(--TABLE-COLOR);
  gap: 0.1em;
}

.table__button {
  padding: 0.25em;
  font-size: 1.5rem;
  color: var(--TABLE-COLOR);
}

.table__thead {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table__th,
.table__cell {
  background-color: var(--TABLE-BGCOLOR);
  text-align: left;
  border: 1px solid var(--TABLE-COLOR);
  padding: 0.5em;
}

.table__cell:last-child {
  display: grid;
  place-content: center;
}

.table__cell--inactive {
  background-color: var(--INACTIVE);
}

/* ||  NOTE */

.note__created,
.note__updated,
.note__username {
  display: none;
}

.note__status {
  font-weight: bold;
}

.note__status--open {
  color: var(--STATUS-OPEN);
}

.note__status--completed {
  color: var(--STATUS-COMPLETE);
}


/* || FORM - GENERAL */

.form {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.75em;
  max-width: 800px;
}

.form__checkbox-container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 0.5em;
}

.form__persist {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5em;
}

.form__title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form__input,
.form__submit-button {
  padding: 0.5em;
  border-radius: 15px;
}

.form__input--text {
  min-height: 150px;
}

.form__input--incomplete {
  border: 1px solid var(--INPUT-INVALID);
  outline: 1px solid var(--INPUT-INVALID);
}

.form__checkbox {
  width: 24px;
  height: 24px;
}

.form__select {
  width: fit-content;
  padding: 0.25em;
}

.form__action-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5em;
  position: absolute;
  right: 0.5em;
}

.form__row {
  display: flex;
  flex-flow: row nowrap;
  gap: 2em;
}

.form__divider {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5em;
}

.nav-toggle {
  display: none;
}

.multi-header {
  height: 15%;
  margin: 0, 0, 0, 1%;
  padding: 0;
  background-color: #171717;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  overflow: hidden;
}
.multi-header-logo {
  height: 100%;
  width: 100%;
  margin-left: 5%;
  max-width: 15vh;
  max-height: 15vh;
}

.vs-header-text {
  font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: #FFEE7E;
  padding-top: 1%;
  text-align: center;
  margin-left: 1%;
}

.vs-multi-footer {
  background-color: #171717;
  position: relative;
  height: 5%;
  bottom: 0;
  width: 100%;
  font-size: medium;
  position: fixed;

}

.footer-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#FFEE7E;
  font-weight: none;
  margin-bottom: 0;
}

.multi-footer-logo {
  height: 100%;
  width: 100%;
  max-width: 4vh;
  max-height: 4vh;
  margin-left: 1%;
} 

.FormSubmitButton{
  border: none;
  width: 40%;
  height: 40%;
  background-color: #FFEE7E;
  font-family: 'Permanent Marker', 'permanent_markerregular' Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  color: 'black';
  font-size: 1.5rem;
  border-radius: 15%;
  cursor: pointer;
  margin-left: auto;
  margin-right: 0;
}

.persistCheck {
  visibility: hidden;
  height: 0;
}

.login-options {
  float:left;
}

.login-options:hover {
  color:#FFEE7E;
  cursor: pointer;
}



@media screen and (max-width: 450px) {
  body {
    -webkit-text-size-adjust: none;
  }
  h1 {
    font-size: 2rem;
  }

  .FormSubmitButton {
    color: black;
  }

  .icon-button {
    font-size: 1.5rem;
  }

  .dash-content {
    width: 250vw;
    display: flex;
    flex-direction: column;
  }
  .dash-actions {
    width: 100vw;
    height: fit-content;
  }

  .current-leagues {
    width: 100vw;
    height: fit-content;
  }

  .multi-header {
    height: 15%;
    margin: 0, 0, 0, 1%;
    padding: 0;
    background-color: #171717;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    overflow: hidden;
  }
  .multi-header-logo {
    height: 100%;
    width: 100%;
    margin-left: 5%;
    max-width: 10vh;
    max-height: 10vh;
  }
  
  .vs-header-text {
    font-family: 'Permanent Marker', 'permanent_markerregular' Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #FFEE7E;
    padding-top: 1%;
    text-align: left;
  }

  .nav-toggle {
    display: flex;
    justify-content: center;
    align-items: baseline;
    border: none;
    background: none;
    color: #FFEE7E;
    margin-left: -30%;
    z-index: 0;
  }

  .dash-header__nav {
    gap: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 150%;
    margin-left: -30%;
    margin-bottom: 10%;
    z-index: 1;
  }

  .no-dash {
    display: none;
  }
}


@media screen and (min-width: 768px) {

  body {
    font-size: 1.25rem;
  }

  .dash-header {
    padding: 0 1em;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
  }

  .dash-container {
    padding: 1em;
  }

  .table {
    font-size: 1.5rem;
  }

  .table--notes {
    grid-template-columns: repeat(3, 1fr) 2fr repeat(2, 1fr);
  }

  .note__created,
  .note__updated,
  .note__username {
    display: block
  }

  .form__action-buttons {
    position: static;
  }
}

