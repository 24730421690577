@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.dash-title {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
}

.calendar-league-container {
    width: 40%;
    margin-left: 5%;
}
.dash-actions {
    width: 35%;
}

a {
    color: black;
    text-decoration: none;
}

.venmo-link {
    color: #ffee7e;
}

.spacer {
    padding-top: 2%;
}

.dash-subs {
    margin-top: 1%;
    margin-bottom: 1%;
    cursor: pointer;
}
.dash-subs:hover {
    color: #ffee7e;
}

.dash-leagues {
    margin-top: 1%;
    margin-bottom: 1%;
    cursor: pointer;
}
.dash-leagues:hover {
    color: #ffee7e;
}

.player-dash-headers {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    margin-left: 2%;
}

.leagues-container {
    height: 100%;
}

.important {
    color:red;
}

.league-rules {
    margin-left: 2%;
    line-height: 150%;
    margin-bottom: 2%;
}

.sub-requests {
    border: 1px black solid;
    border-radius: 1rem;
    height: fit-content;
    background-color: white;
    padding-left: 2%;
    padding-bottom: 3%;
    box-shadow: 1rem 1rem 1rem black;
}

.player-leagues {
    border: 1px solid black;
    background-color: white;
    height: fit-content;
    padding-left: 2%;
    margin-right: 2rem;
    border-radius: 1rem;
    box-shadow: 1rem 1rem 1rem black;
}

.league-subheaders {
    background-color: #ffee7e;
    
}

.current-leagues {
    border: 1px solid black;
    background-color: white;
    height: fit-content;
    padding-left: 2%;
    margin-right: 2rem;
    border-radius: 1rem;
    padding-bottom: 3%;
    box-shadow: 1rem 1rem 1rem black;
}

.indiv-leagues {
    padding-left: 5%;
    overflow: auto;
}

.league-title {
    font-family: 'Permanent Marker','permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-left: 2%;
    padding-bottom: 1%;
}

.open-leagues {
    display: flex;
    justify-content: center;
    width: 75%;
}

.league-headers {
    background-color: black;
    color:#ffee7e;
    width: 100%;
}

.partners {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.edit-title {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.edit-center {
    display: flex;
    justify-content: center;
}

Link {
    font-style: none;
    text-decoration: none;
}

.edit-form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    align-content: center;
    border: black 1px solid;
    border-radius: 10%;
    padding: 2rem;
    width: fit-content;
}

.sub-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 20%
}

.edit-sub-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 20rem;
    margin-top: 1rem;
}

.sub-button {
    border: none;
    background-color: black;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #ffee7e;
    margin-left: 2%;
    padding: 1rem;
    border-radius: 10%;
    cursor: pointer;
}

.sub-links {
    margin-left: 2%;
    padding-bottom: 0.5rem;
}

.svl-links {
    margin-left: 2%;
    padding-bottom: 0.5rem;
}
.league-links {
    margin-left: 2%;
    padding-bottom: 0.5rem;
}

.league-links:hover{
    cursor: pointer;
    color: #ffee7e
}

.league-info-buffer{
    padding-bottom: 0.2%;
}

.waitlist {
    margin-left: 3.75%;
    padding: 1vh 0;
}
.waitlist-subheader {
    margin-left: 2%;
    padding: 1vh 0;
}

.promote-button {
    border: none;
    background-color: black;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: #ffee7e;
    margin: 1% 0 0 2%;
    padding: .1rem .25rem;
    border-radius: 10%;
    cursor: pointer;
}

.delete-button {
    border: none;
    background-color: red;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: white;
    margin-left: 2%;
    padding: 1rem;
    border-radius: 10%;
    cursor: pointer;
}

.sub-table-header-group {
    background-color: black;
    color: #ffee7e;
    
}

.sub-table-header {
    padding-left: 1rem;
    width: auto;
    border-bottom: black 1px solid;
}

.sub-row {
    display: table-row;
    text-align: center;
    vertical-align: middle;
    background-color: white;
    color: black;
}

.invite-button {
    border: black solid 1px;
    color: black;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    background-color: #ffee7e;
    padding: .125rem;
    cursor: pointer;
}

.notifications {
    color: red;
    font-weight: bolder;
}

.profile-page {
    display: flex;
    width: fit-content;
}

.profile-links-container {
    padding-left: 1rem;
    padding-right: 2%;
    width: 100vh;
}

.profile-leagues-container {
    height: 50vh;
    width: 55%;
}

.profile-links {
    background-color: white;
    padding: 1rem;
    border: solid black 1px;
}

.profile-links-header {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    margin-left: 2%;
}

.player-schedule {
    border: 1px solid black;
    height: 100%;
    background-color: white;
    overflow: scroll;
}

.edit-button {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    background-color: #ffee7e;
    color: black;
    width: 50%;
    border-radius: 15%;
    margin-top: 1rem;
    cursor: pointer;
}

.player-info {
    margin: 1%;
    padding: 1%;
    width: 70%;
}

.matchday {
    margin: .5rem 0;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
}

.checkout-page {
    width: 40rem;
}

.checkout-fees {
    padding: 2% 0;
}

.cc-payment-form {
    width: 75%;
    padding: 3%;
    border: black 1px solid;
    border-radius: 1rem;
}

.checkout-button-container {
    padding: 2% 0;
}
.checkout-pay-button {
    background-color: black;
    color: #ffee7e;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    padding: 1%;
    border: none;
    border-radius: 7%;
    cursor: pointer;
}

.stripe-creds {
    display: flex;
    align-items: center;
}
.stripe-logo {
    height: 10%;
    width: 10%;
} 

.success-details {
    padding: 2%;
}

.sub-accept-decline {
    display: flex;
    flex-direction: row;
    padding-left: 2%;
    margin-top: 1%;
}

.accept {
    padding: 1%;
    border: none;
    background-color: green;
    color: white;
    border-radius: 7%;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
}

.decline {
    margin-left: 2%;
    padding: 1%;
    border: none;
    background-color: red;
    color: white;
    border-radius: 7%;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    cursor: pointer;
}

.info-edit-button {
    margin-top: 3%
}

.invisible {
    display: none;
}

.homepage-desktop {
    display: contents;
}

.homepage-mobile {
    display: none;
}


@media only screen and (max-width: 450px) {
    .homepage-desktop {
        display: none;
    }

    .homepage-mobile {
        display: block;
    }

    .calendar-league-container {
        width: 40%;
        padding-right: 2%;
        margin: 2% 0 0 0;
        padding-bottom: 4%;
    }

    .league-subheaders {
        width: 15rem;
    }

    .current-leagues {
        width: 98%;
        overflow-x: wrap;
    }
    .league-table-header {
        display: inline-block;
    }
    .player-leagues {
        margin-bottom: 7%;
    }
    
    .visible {
        display: none;
    }

    .invisible {
        display: inline;
    }

    .session-table-header-group {
        max-width: 100%;
    }

    .cc-payment-form {
        width: 50%;
        border-radius: 1rem;
    }    
}