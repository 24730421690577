@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

.PlayerSignUpFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    margin-top: 5%;
}

.PlayerSignUpTitle {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 2rem;
}

.PlayerSignUpForm {
    display: flex;
    flex-direction: column;
    border: .125rem solid #171717;
    border-radius: 1rem;
    padding: 1.5rem;
    align-items: flex-start;
}

.forgotPasswordContainer {
    padding-left: 2%;
    margin-left: 2%;
}

input, select {
    margin-left: 1rem;
    padding: .5rem;
    margin-bottom: 1rem;
    border-radius: 1rem;
    border: 1px gray solid;
}

select {
    margin-left: 1rem;
    padding: .5rem;
    border-radius: 1rem;
}

textarea {
    border-radius: 1rem;
    height: 5rem;
    padding: .5rem;
}

.FormSubmitButton{
    border: none;
    width: 50%;
    height: 30%;
    background-color: #FFEE7E;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
    color: black;
}

.ForgotSubmitButton{
    border: none;
    width: 30%;
    height: 20%;
    background-color: #FFEE7E;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 1.5rem;
    border-radius: 1rem;
    cursor: pointer;
}



.SVLHostFormContainer {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;
}

.SVLHostTitle {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 2rem;
}

.SVLHostSubtitle {
    font-size: 1.25rem;
    padding-bottom: .5rem;
}

.SVLHostSubtitle2 {
    font-size: 1.25rem;
    padding-bottom: .5rem;
}

.SVLHostForm {
    display: flex;
    flex-direction: column;
    border: .125rem solid #171717;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 1rem;
}

.MultiPromoteFormContainer {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
    padding: 0 1%;
}

.MultiPromoteFormTitle {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 2rem;
}

.MultiPromoteFormSubtitle {
    font-size: 1.25rem;
    margin-bottom: 1%;
}

.MultiPromoteForm {
    display: flex;
    flex-direction: column;
    border: .125rem solid #171717;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 1rem;
}
.MultiPromoteTextareaLabel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.MultiPromoteTextarea {
    width: 100%;
    height: 4rem;
}

.MultiContactFormContainer {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1%;
    
}
.MultiContactFormTitle {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 2rem;
}

.MultiContactForm {
    display: flex;
    flex-direction: column;
    border: .125rem solid #171717;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 1rem;
}
.MultiContactTextareaLabel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.MultiContactTextarea {
    width: 100%;
    height: 5rem;
}
.PlayerLoginFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}
.PlayerLoginFormTitle {
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 2rem;
}

.PlayerLoginForm {
    display: flex;
    flex-direction: column;
    border: .125rem solid #171717;
    padding: .5rem;
    align-items: flex-start;
    width: 20rem;
    align-items: center;
    border-radius: 10%;
}

.sentMessageContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
}

.aboutUsContainer{
    display: flex;
    flex-direction: column;
    align-items: left;
    margin: 5% 10% 5% 10%;
}

.about-us-lines {
    margin: 2% 0 2% 2%;
    line-height: 150%;
}

.about-link {
    text-decoration: underline;
}


.sentMessageTitle{
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 2rem;
    margin-bottom: 2%;
}

.sentButton{
    border: none;
    width: 40%;
    height: 40%;
    margin-top: 3%;
    background-color: #FFEE7E;
    font-family: 'Permanent Marker', 'permanent_markerregular', Arial, sans-serif;
    font-style: normal;
    font-weight: normal;
    color: 'black';
    font-size: 1.5rem;
    border-radius: 5%;
    cursor: pointer;
}

.header-container {
    display: inline-flex;
    background-color: #171717;
    align-items: center;
    color:#FFEE7E;
}

.dash-header-links {
    padding-right: 5%;
}

.emailAllBody {
    width: 90%;
}


@media only screen and (max-width: 450px) {
    .SVLHostSubtitle2 {
        padding-left: 2rem;
    }

    .sent-subtitle {
        padding-left: 2%;
    }
    .sentButton {
        color: black;
    }
    .ForgotSubmitButton {
        color: black;
    }
}

